'use strict';

Gri.module({
  name: 'utils',
  ieVersion: null,
  $el: $('[c-helper]'),
  container: window,
  fn: function () {

    let helpersFn = this.helpers;

    this.$el.each(function (index, item) {
      let helpers = $(item).attr('c-helper').split(';');
      $.each(helpers,function (_index,_item) {
        let keyValue = _item.split(':');
        let key = keyValue[0];
        let value = keyValue[1];
        helpersFn(key,value,item);
      });
    });
  },
  helpers:function (key, value, element) {
    let windowW = $(window).width();
    if(windowW < 768) {
      value = eval(value / 2);
    } else {
      value = eval(value);
    }
    //Margins
    if(key == "mrT"){
      $(element).css('margin-top',value);
    }
    if(key == "mrB"){
      $(element).css('margin-bottom',value);
    }
    if(key == "mrL"){
      $(element).css('margin-left',value);
    }
    if(key == "mrR"){
      $(element).css('margin-right',value);
    }

    //Paddings
    if(key == "pdT"){
      $(element).css('padding-top',value);
    }
    if(key == "pdB"){
      $(element).css('padding-bottom',value);
    }
    if(key == "pdL"){
      $(element).css('padding-left',value);
    }
    if(key == "pdR"){
      $(element).css('padding-right',value);
    }
  }
});
